<template>
  <div class="page-wrapper white">
    <div class="page-info search-wrapper w-200">
      <div class="search-item w-280">
        <el-date-picker
          size="small"
          v-model="formData.date"
          :picker-options="pickerOptions"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="handleChange"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <el-select v-model="formData.school_id" placeholder="请选择校区" size="small">
          <el-option v-for="item of school" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select v-model="formData.role_type" placeholder="请选择用户角色" size="small">
          <el-option v-for="item of role" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-button @click="onDownload" size="small" type="primary" :disabled="disabled">
          <i class="el-icon-download"></i>
          <span>导出</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { setDatePicker } from '@/utils'
import { getApi2, fileDownload } from '@/api'
export default {
	_config:{"route":{"path":"index","meta":{"title":"操作"}}},
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const [start, end] = [setDatePicker({ num: 7 }), setDatePicker()]
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const [start, end] = [setDatePicker({ num: 1, type: 'month' }), setDatePicker()]
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const [start, end] = [setDatePicker({ num: 3, type: 'month' }), setDatePicker()]
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formData: {
        date: '',
        school_id: '',
        role_type: '',
        start_date: '',
        end_date: ''
      },
      school: [],
      role: []
    }
  },
  computed: {
    disabled() {
      let isDisabled = false
      Object.keys(this.formData).forEach(key => {
        if (this.formData[key] || this.formData[key] === 0) {
          return
        }
        isDisabled = true
      })
      return isDisabled
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    getOptions() {
      getApi2('/common/screen/school').then(res => {
        this.school = res.map(item => ({
          value: item.id,
          label: item.school_name
        }))
        if (this.school.length) {
          this.school.unshift({ label: '全部', value: 0 })
        }
      })
      getApi2('/common/screen/role-type').then(res => {
        this.role = res.map(item => ({
          value: item.id,
          label: item.name
        }))
      })
    },
    handleChange(e) {
      if (!e) {
        this.formData.start_date = ''
        this.formData.end_date = ''
        return
      }
      const [start_date, end_date] = e
      this.formData.start_date = start_date
      this.formData.end_date = end_date
    },
    onDownload() {
      this.$confirm('确认导出数据?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const { date, ...data } = this.formData
        fileDownload('/api2/api/tool/leading-out/memorandum', {},  { httpType: 'post', data, name: '备忘录数据', show: true })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-date-editor {
    width: 100%;
  }
}
</style>
